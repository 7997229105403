<template>
  <div class="row">
    <div v-if="model" class="col-12 col-md q-pa-xs">
      <form-builder :schema="schema" />
    </div>

    <div v-if="phoneModel" class="col-12 col-md-4 q-pa-xs">
      <form-builder :schema="phoneSchema" />
    </div>

    <div v-if="addressModel" class="col-12 col-md">
      <form-builder :schema="addressSchema" />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['phone', 'profile', 'address'],
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    },
    phoneModel: {
      type: Object,
      default () {
        return {}
      }
    },
    addressModel: {
      type: Object,
      default () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    phoneType: {
      type: String,
      default () {
        return 'tel'
      }
    },
    appOptions: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    schema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                disabled: this.disabled,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (name) => {
                  this.$emit('profile', { ...this.model, name })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'surname',
                value: this.model.surname,
                disabled: this.disabled,
                label: this.$t('Second name'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (surname) => {
                  this.$emit('profile', { ...this.model, surname })
                }
              },
              {
                type: 'input',
                inputType: 'email',
                field: 'email',
                value: this.model.email,
                required: false,
                disabled: this.disabled,
                label: this.$t('E-mail'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (email) => {
                  this.$emit('profile', { ...this.model, email })
                }
              }
            ]
          }
        ]
      }
    },
    phoneSchema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: this.phoneType,
                maxlength: 255,
                field: 'phone',
                value: this.phoneModel.phone,
                disabled: this.disabled,
                label: this.$t('Phone'),
                wrapperStyleClasses: 'col-12',
                onChange: phone => {
                  this.$emit('phone', { ...this.phoneModel, phone })
                }
              }
            ]
          }
        ]
      }
    },
    addressSchema () {
      if (this.type === 'domestic') {
        return {
          isLoading: this.isLoading,
          groups: [
            {
              styleClasses: 'row',
              fields: [
                {
                  type: 'input',
                  inputType: 'text',
                  field: 'street',
                  value: this.addressModel.street,
                  disabled: this.disabled,
                  required: !this.notRequired,
                  label: this.$t('Address Line 1'),
                  hint: this.$t('Street address, P.O. box, company name, c/o'),
                  wrapperStyleClasses: 'col-12 q-pa-xs',
                  onChange: street => {
                    this.$emit('address', { ...this.addressModel, street })
                  }
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: this.$t('Address Line 2'),
                  field: 'house',
                  value: this.addressModel.house,
                  hint: this.$t('Apartment, suite, unit, building, floor, etc'),
                  disabled: this.disabled,
                  wrapperStyleClasses: 'col-12 q-pa-xs',
                  onChange: (house) => {
                    this.$emit('address', { ...this.addressModel, house })
                  }
                }
              ]
            }
          ]
        }
      }

      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'street',
                required: !this.notRequired,
                value: this.addressModel.street,
                disabled: this.disabled,
                label: this.$t('Street'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: street => {
                  this.$emit('address', { ...this.addressModel, street })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'house',
                disabled: this.disabled,
                label: this.$t('House'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.addressModel.house,
                onChange: house => {
                  this.$emit('address', { ...this.addressModel, house })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'quarter',
                disabled: this.disabled,
                label: this.$t('Quarter'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.addressModel.quarter,
                onChange: quarter => {
                  this.$emit('address', { ...this.addressModel, quarter })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'block',
                disabled: this.disabled,
                label: this.$t('Block'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.addressModel.block,
                onChange: block => {
                  this.$emit('address', { ...this.addressModel, block })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'entrance',
                disabled: this.disabled,
                label: this.$t('Entrance'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.addressModel.entrance,
                onChange: entrance => {
                  this.$emit('address', { ...this.addressModel, entrance })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'floor',
                disabled: this.disabled,
                label: this.$t('Floor'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.addressModel.floor,
                onChange: floor => {
                  this.$emit('address', { ...this.addressModel, floor })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'apartment',
                disabled: this.disabled,
                label: this.$t('Apartment'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.addressModel.apartment,
                onChange: apartment => {
                  this.$emit('address', { ...this.addressModel, apartment })
                }
              },
              {
                type: 'address',
                label: this.$t('Free-form address'),
                field: 'notFormal',
                value: this.addressModel.notFormal,
                disabled: this.disabled,
                wrapperStyleClasses: this.appOptions.googleApiKey
                  ? 'col-12 q-pa-xs'
                  : 'd-none',
                googleApiKey: this.appOptions.googleApiKey,
                onInput: (notFormal) => {
                  this.$emit('address', { ...this.addressModel, notFormal })
                },
                onPlaceChanged: (value, place) => {
                  this.currentPlace = place
                  this.addMarker()

                  let localAddress = {
                    quarter: place.neighborhood,
                    postcode: place.postal_code,
                    notFormal: this.addressModel.notFormal,
                    _embedded: {
                      locality: this.addressModel._embedded.locality
                    }
                  }

                  if (place.route) {
                    localAddress.street = place.route
                  }

                  if (place.street_number) {
                    localAddress.house = place.street_number
                  }

                  localAddress.geo = {
                    latitude: value.y,
                    longitude: value.x
                  }

                  if (!place.postal_code) {
                    this.addressModel = localAddress
                    this.$emit('address', this.addressModel)
                    return Promise.resolve(localAddress)
                  }

                  localAddress.postcode = place.postal_code

                  return this.loadLocalitiesByPostcode(localAddress.postcode)
                    .then(locality => {
                      localAddress._embedded.locality = locality
                      this.$emit('address', localAddress)
                      return localAddress
                    })
                }
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
